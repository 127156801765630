import React from 'react';
import ReactDOM from 'react-dom';
import '../../../../sass/base.scss';
import '../../../../sass/components/header/base_header.scss';
import '../../../../sass/components/utils.scss';
import '../../../../sass/pages/listing/desktop/badges.scss';
import '../../../../sass/pages/listing/desktop/page_tabs.scss';
import '../../../../sass/pages/listing/desktop/report_ad_box.scss';
import '../../../../sass/components/safety_tip.scss';
import '../../../../sass/components/tool_tip.scss';
import '../../../../sass/components/message_box.scss';
import '../../../../sass/components/verified_box.scss';
import '../../../../sass/pages/listing/report_ad.scss';
import '../../../../sass/pages/listing/co_living_amenities.scss';
import '../../../../sass/pages/listing/desktop_gallery.scss';
import '../../../../sass/pages/listing/desktop/offered.scss';
import '../../../../sass/components/verified_promo_modal.scss';
import { fetchData } from '../../../helpers/base_data_fetcher.js';
import get from '../../../helpers/data_layer/get.js';
import ShareFallback from '../components/share_fallback/index';
import googleMaps from '../../../services/maps/google/index.js';
import appleMaps from '../../../services/maps/mapKitJs/index.js';
import triggerRegistrationPrompt from '../reg_prompt.js';
import storeLastViewedListing from '../components/storeLastViewedListing';
import to_upper_case_first from '../../../helpers/to_upper_case_first';
import renderReportAdModal from '../report_ad_modal.jsx';
import '../../../components/old/overlay.js';
import '../components/old_desktop_gallery.js';
import { track_share_button, track_native_share } from '../tracking';
import InitialisePromoModal from '../../../components/GetVerifiedModal';

renderReportAdModal();
InitialisePromoModal();

const advert_data = get('page.advert');
const listingType = advert_data.type;

const shareButtons = document.querySelectorAll('[data-share-button]');
const canonicalLink = document.querySelector('link[rel="canonical"]');
const canonicalUrl = canonicalLink.href;

async function openNativeShareOptions() {
  const { trackingLocation } = this.dataset;
  track_share_button({ location: trackingLocation });
  navigator
    .share({
      text: to_upper_case_first(advert_data.share_title),
      url: canonicalUrl,
    })
    .then(() => track_native_share({ success: true }))
    .catch((error) => track_native_share({ success: false }));
}

if (shareButtons.length) {
  if (navigator.share) {
    shareButtons.forEach((button) =>
      button.addEventListener('click', openNativeShareOptions),
    );
  } else if (!navigator.share) {
    fetchData('page.localisations').then((localisations) => {
      // should be able to code split this
      let fallbackMountElement = document.querySelector('#share-fallback-app');
      if (!fallbackMountElement) {
        fallbackMountElement = document.createElement('div');
        fallbackMountElement.id = 'share-fallback-app';
        document.body.appendChild(fallbackMountElement);
      }
      ReactDOM.render(
        <ShareFallback
          shareUrl={canonicalUrl}
          shareTitle={to_upper_case_first(advert_data.share_title)}
          flatshareType={listingType}
          advertId={advert_data.id}
          modalLabelText={localisations.share_ad}
          copyLinkText={localisations.copy_link}
          emailText={localisations.email}
        />,
        fallbackMountElement,
      );
    });
  }
}

function addTrackingToBackLink(event) {
  if (!window.dataLayer) {
    return false;
  }
  const adType = listingType == 'wanted' ? 'flatmate' : 'flatshare';
  return window.dataLayer.push({
    event: 'ga.event',
    ga_event_category: 'nav',
    ga_event_action: `${adType}_details_back`,
    ga_event_label: '',
  });
}

const backLink = document.querySelector('.back_link');
if (backLink) {
  backLink.addEventListener('click', addTrackingToBackLink);
}

const searchParam = new URLSearchParams(window.location.search);
let aggregatorName = '';
if (searchParam.has('utm_source')) {
  aggregatorName = searchParam.get('utm_source').toLowerCase();
}

triggerRegistrationPrompt();

let mapLoaded = false;

async function openMap({
  event,
  latitude,
  longitude,
  mapMountElement,
  domain,
  base_url,
  useAppleMaps,
  appleMapsToken,
}) {
  if (mapLoaded) {
    return true;
  }
  const maps = useAppleMaps ? appleMaps : googleMaps;
  const mapsInstance = await maps.load({
    domain,
    base_url,
    JWT: appleMapsToken,
  });
  const map = maps.createFromCenter(mapsInstance, {
    mountElement: mapMountElement,
    latitude,
    longitude,
  });
  mapLoaded = true;
  maps.addMarker(mapsInstance, {
    map,
    latitude,
    longitude,
  });
  return true;
}

const mapTrigger = document.querySelector('#mapLink');
const mapMountElement = document.querySelector('#map');
if (mapTrigger) {
  Promise.all([
    fetchData('page.location'),
    fetchData('site.domain_name'),
    fetchData('site.base_url'),
    fetchData('config.features.apple_maps_desktop_detail'),
    fetchData('page.apple_maps_token'),
  ]).then(([location, domain, base_url, useAppleMaps, appleMapsToken]) => {
    if (location && location.latitude) {
      const { latitude, longitude } = location;
      mapTrigger.addEventListener('click', (event) =>
        openMap({
          event,
          latitude,
          longitude,
          mapMountElement,
          domain,
          base_url,
          useAppleMaps,
          appleMapsToken,
        }),
      );
    }
  });
}

storeLastViewedListing(listingType);

// Hide amenites block if none
const amenities = document.getElementById('coLivingAmenities');

if (amenities) {
  const amenitesList = document
    .getElementById('coLivingList')
    .querySelectorAll('li');

  if (!amenitesList.length) {
    amenities.classList.add('co-living-amenities--hidden');
  }
}

// Initialise old photo gallery

let gallery;

const photoElements = document.querySelectorAll(
  '.photo-gallery__thumbnail-link',
);
photoElements[0] && (new Image().src = photoElements[0].getAttribute('href'));
photoElements.forEach((photo) => {
  photo.addEventListener('click', (event) => {
    event.stopPropagation();
    event.preventDefault();
    showPhoto(photo);
  });
});

const photoGallery = () => {
  const photos = [];

  photoElements.forEach((photo) => {
    photos.push({
      number: photo.dataset.number,
      url: photo.getAttribute('href'),
      caption: photo.dataset.caption,
      adtitle: photo.dataset.title,
      subtitle: photo.dataset.subtitle,
      price: photo.dataset.price,
      pricepre: photo.dataset.pricepre,
      per: photo.dataset.per,
    });
  });

  gallery = new window.SR.overlay.Gallery(photos);
};

const showPhoto = (photo) => {
  const index = photo.dataset.number - 1;
  if (!(gallery instanceof window.SR.overlay.Gallery)) {
    photoGallery();
  }
  gallery.show(index < 0 ? 0 : index);
};

// Area Info

let areaInfo;
const areaInfoLink = document.querySelector('a[rel="where_to_live_wizard"]');
if (areaInfoLink) {
  areaInfoLink.addEventListener('click', (event) => {
    event.stopPropagation();
    event.preventDefault();
    if (!(areaInfo instanceof window.SR.overlay.Iframe)) {
      areaInfo = new window.SR.overlay.Iframe({
        classes: 'area_info',
        url: event.target.href,
        width: 400,
        height: 300,
      });
    }
    areaInfo.open();
    return false;
  });
}

// Looking In

const lookingIn = document.getElementById('looking_in');
if (lookingIn) {
  const items = lookingIn.querySelectorAll('li');
  if (items && items.length > 4) {
    const extraItems = lookingIn.querySelectorAll(`li:nth-child(n+5)`);
    if (extraItems) {
      extraItems.forEach((extraItem) => {
        extraItem.style.display = 'none';
      });
      const liEl = document.createElement('li');
      const aEl = document.createElement('a');
      aEl.href = '#';
      aEl.innerText = 'See more...';
      aEl.addEventListener('click', (event) => {
        event.preventDefault();
        extraItems.forEach((extraItem) => {
          extraItem.style.display = 'list-item';
        });
        liEl.remove();
      });
      liEl.appendChild(aEl);
      lookingIn.appendChild(liEl);
    }
  }
}

// Description toggle

const toggleButton = document.getElementById('buttonReadMore');
const descriptionBody = document.querySelector('.feature__description-body');

if (toggleButton) {
  fetchData('page.localisations').then((localisations) => {
    if (descriptionBody.clientHeight < descriptionBody.scrollHeight) {
      toggleButton.classList.remove('feature__description-read-more--hidden');
      toggleButton.addEventListener('click', () => {
        descriptionBody.classList.toggle('feature__description-body--full');
        const currentText = toggleButton.innerText;
        if (currentText == localisations.read_less) {
          descriptionBody.scrollIntoView();
        }
        toggleButton.innerText =
          currentText == localisations.read_more
            ? localisations.read_less
            : localisations.read_more;
      });
    }
  });
}
